<template>
  <div>
    <v-row class="justify-center mx-0">
      <v-alert border="left" type="info" text width="815" class="mx-1 mt-3"
        >Der Emailversand ist momentan Server-seitig deaktiviert.</v-alert
      >
    </v-row>
    <v-row class="justify-center mx-0">
      <v-card
        :outlined="$vuetify.breakpoint.mdAndUp"
        width="815"
        class="mb-4"
        :loading="sending"
        :flat="$vuetify.breakpoint.smAndDown"
      >
        <v-card-title>
          {{ subject.trimStart() || "Neue Nachricht" }}
        </v-card-title>
        <v-divider />
        <v-card-text class="mb-0 pb-0 px-1 px-md-3">
          <v-autocomplete
            hide-details
            chips
            solo
            flat
            multiple
            hide-no-data
            :items="possibleReceivers"
            v-model="to"
          >
            <template v-slot:prepend-inner>
              <div class="pt-0 font-weight-medium">An:</div>
            </template>
          </v-autocomplete>
          <v-divider />
          <v-text-field hide-details solo flat v-model="subject">
            <template v-slot:prepend-inner>
              <div class="pt-0 font-weight-medium">Betreff:</div>
            </template>
          </v-text-field>
          <v-divider />
          <text-editor ref="textEditor" />
          <v-divider />
          <v-file-input
            v-model="files"
            class="mt-1"
            dense
            flat
            solo
            chips
            counter
            show-size
            multiple
            label="Dateianhänge"
          />
        </v-card-text>
        <v-card-actions class="px-4 pb-3">
          <v-btn
            text
            @click="openConfirmationDialog()"
            :disabled="to.length === 0"
          >
            Senden
            <v-icon class="ml-1">
              send
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset()" v-on="on">
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Entwurf löschen</span>
          </v-tooltip>
        </v-card-actions>
        <v-divider v-if="$vuetify.breakpoint.smAndDown" />
      </v-card>
    </v-row>
    <v-dialog v-model="confirmSendDialog" max-width="290">
      <v-card>
        <v-card-title>
          {{ confirmSendDialogText }}
        </v-card-title>
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmSendDialog = false">
            Abbruch
          </v-btn>
          <v-btn text @click="sendEmail()">
            Senden
            <v-icon small class="ml-1">
              send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mt-6"></div>
    <v-snackbar v-model="error.occured" :timeout="3000" color="error">
      {{ error.text }}
      <v-btn text @click="error.occured = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import MailAPI from "../api/MailAPI";
import TextEditor from "@/components/utility/TextEditor";
import { mapActions } from "vuex";

export default {
  name: "Email",
  components: {
    "text-editor": TextEditor
  },
  data() {
    return {
      subject: "",
      to: [],
      possibleReceivers: [],
      files: [],
      error: {
        occured: false,
        text: ""
      },
      confirmSendDialog: false,
      confirmSendDialogText: "",
      sending: false,
      linkUrl: null,
      linkMenuIsActive: false
    };
  },
  computed: {
    toolbarColor() {
      if (this.$store.state.settings.darkMode) {
        return "primary";
      } else {
        return "grey lighten-4";
      }
    }
  },
  methods: {
    ...mapActions("snackbar", ["showSnackbar"]),
    sendEmail() {
      this.confirmSendDialog = false;
      this.sending = true;
      let payload = {
        attachments: [],
        subject: this.subject,
        body: this.$refs.textEditor.getHTML(),
        receiverGroups: this.to
      };
      this.files.forEach(file => {
        payload.attachments.push({
          name: file.name,
          temp: true,
          inline: false
        });
        payload[file.name] = file;
      });
      console.log(payload);
      MailAPI.sendEmail(payload)
        .then(() => {
          this.showSnackbar({
            text: "Email erfolgreich gesendet."
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
    openConfirmationDialog() {
      if (!this.subject) {
        this.confirmSendDialogText =
          "Betreff der Nachricht nicht angegeben. Trotzdem senden?";
      } else {
        this.confirmSendDialogText = "Nachricht senden?";
      }
      this.confirmSendDialog = true;
    },
    reset() {
      this.to = [];
      this.subject = "";
      this.$refs.textEditor.reset();
      this.files = [];
    }
  },
  mounted() {
    let vueI = this;
    MailAPI.getReceivers()
      .then(data => {
        vueI.possibleReceivers = data;
      })
      .catch(error => {
        console.log(error);
        vueI.error = {
          occured: true,
          text: error.response.data
        };
      });
  }
};
</script>

<style></style>
