import axiosClient from "./AxiosConfig";

export default {
  sendEmail(payload) {
    console.log(payload);
    let payloadString = JSON.stringify(payload);
    let formData = new FormData();
    formData.append("request", payloadString);
    payload.attachments.forEach(attachment => {
      console.log(attachment);
      let file = payload[attachment.name];
      console.log(file);
      formData.append(attachment.name, file);
    });
    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return axiosClient.post("/email", formData, {
      headers: { "content-type": "multipart/form-data" }
    });
  },

  getReceivers() {
    return axiosClient.get("/email/receivers").then(response => {
      return response.data;
    });
  }
};
